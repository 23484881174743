let key = {};
let env = "demo";
// export const API_URL = "http://localhost:2038";
// export const API_URL = "https://api.trade11.io";
export const API_URL = "https://backend-trade11.maticz.in";

// export const PORT = 2038;
// if (process.env.NODE_ENV === "production") {
if (env === "production") {
  console.log("Set Production Config");
  const API_URL = "https://api.trade11.io";

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    Recaptchakey: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
    API_URL: API_URL,
    FRONT_URL: "https://www.trade11.io/",
    ADMIN_URL: "https://control-fhak.trade11.io",
    getGeoInfo: "https://ipapi.co/json/",
  };
} else if (env === "demo") {
  console.log("Set Demo Config");
  const API_URL = "https://backend-trade11.maticz.in";

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    Recaptchakey: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
    API_URL: API_URL,
    FRONT_URL: "https://trade11-bot.maticz.in/",
    ADMIN_URL: "https://trade11-bot-admin.maticz.in",
    getGeoInfo: "https://ipapi.co/json/",
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    Recaptchakey: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
    API_URL: `${API_URL}:2038`,
    FRONT_URL: "http://localhost",
    ADMIN_URL: "http://localhost:3001/admin",
    getGeoInfo: "https://ipapi.co/json/",
  };
}

export default key;

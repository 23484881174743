import React from "react";
import { NavLink } from "react-router-dom";
import nav from "../config/routes";
import { removeAuthToken } from "../lib/localStorage";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty } from "../lib/isEmpty";

function Sidebar() {
  const history = useHistory();
  const logout = async () => {
    try {
      history.push("/");
      removeAuthToken();
    } catch (err) {
      console.log(err, "err_logout");
    }
  };

  let { restriction } = useSelector((state) => state.isRun);

  return (
    <>
      <div className="sidebar d-flex flex-column justify-content-between align-items-start">
        <div className="w-100">
          <div className="sidebar_logo_holder px-4 py-3 d-flex justify-content-center align-items-center">
            <img
              src={require("../assets/images/logo.svg").default}
              className="img-fluid main_logo"
            />
          </div>
          <ul className="pt-4 sidebar__scrollUl">
            {nav && nav.length > 0
              ? nav.map((item) => {
                  // let Restriction = restriction
                  if (
                    restriction?.length > 0 &&
                    restriction?.includes(item.path)
                  ) {
                    return (
                      <NavLink
                        to={item.path}
                        className="sidebar_links  rounded-end-5">
                        <li className="rounded-end-5 d-flex gap-3 p-2 ps-4 justify-content-start align-items-center mb-3">
                          <img
                            src={item.image}
                            className="img-fluid sidebar_linkImg"
                          />
                          <p className="sidebar_link_hint">{item.name}</p>
                        </li>
                      </NavLink>
                    );
                  } else if (isEmpty(restriction)) {
                    return (
                      <li className="rounded-end-5 mb-3">
                        <NavLink
                          to={item.path}
                          className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                          <img
                            src={item.image}
                            className="img-fluid sidebar_linkImg"
                          />
                          <p className="sidebar_link_hint">{item.name}</p>
                        </NavLink>
                      </li>
                    );
                  }
                })
              : ""}

            {/* <li className="rounded-end-5 mb-3">
              <NavLink
                to="/dashboard"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/grid.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Dashboard</p>
              </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/supportTicket"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <imgp-2 ps-4
                  src={require("../assets/images/ticket.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Support</p>
              </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/settings"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/pageSetting.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Page Settings</p>
              </NavLink>
            </li>
            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/siteSettings"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/siteSetting.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Site Settings</p>
              </NavLink>
            </li>
            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/userSettings"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/userSetting.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Settings</p>
              </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/users"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/userSetting.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Users</p>
              </NavLink>
            </li>


            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/tokens"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/network.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Tokens</p>
              </NavLink>
            </li>



            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/pairs"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/network.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Pairs</p>
              </NavLink>
            </li>


            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/exchanges"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/exchange.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Exchanges</p>
              </NavLink>
            </li>



            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/subscription"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/prime.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Subscription</p>
              </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/tradeHistory"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/trade.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Trade History</p>
              </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/scheduleHistory"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/scheduler.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Schedule History</p>
              </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
              <NavLink
                to="/subAdmin"
                className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img
                  src={require("../assets/images/subadmin.svg").default}
                  className="img-fluid sidebar_linkImg"
                />
                <p className="sidebar_link_hint">Sub Admin</p>
              </NavLink>
            </li> */}
          </ul>
        </div>

        <li
          className="rounded-end-5 d-flex justify-content-start align-items-center gap-3 mb-3 p-2 ps-4"
          onClick={() => logout()}>
          {/* <NavLink className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4"> */}
          <img
            src={require("../assets/images/logout.svg").default}
            className="img-fluid sidebar_linkImg"
          />
          <p className="sidebar_link_hint">Logout</p>
          {/* </NavLink> */}
        </li>
      </div>
    </>
  );
}

export default Sidebar;
